<template>
  <v-card elevation="4" class="ma-2" width="990">
    <v-card-text>
      <vue-mathjax :formula="formula" class="title text--primary"></vue-mathjax>
    </v-card-text>
  </v-card>
</template>

<script>
import { VueMathjax } from 'vue-mathjax';
export default {
  components: {
    'vue-mathjax': VueMathjax,
  },
  computed: {
    formula() {
      return `$$U_w = {A_f U_f + A_g U_g + l_g \\psi_g \\over A_f + A_g}
      = {${this.$store.state.Af} \\times ${this.$store.state.Uf} +
      ${this.$store.state.Ag} \\times ${this.$store.state.Ug} +
      ${this.$store.state.lg} \\times ${this.$store.state.psi}
      \\over ${this.$store.state.Af} + ${this.$store.state.Ag}}
      = \\color{blue}{\\textbf{${this.Uw}}} \\hspace{2mm} W/(m^2.C)$$`;
    },
    Uw() {
      if (Number(this.$store.state.Af) + Number(this.$store.state.Ag) !== 0) {
        let resultado =
          (Number(this.$store.state.Af) * Number(this.$store.state.Uf) +
            Number(this.$store.state.Ag) * Number(this.$store.state.Ug) +
            Number(this.$store.state.lg) * Number(this.$store.state.psi)) /
          (Number(this.$store.state.Af) + Number(this.$store.state.Ag));
        return Math.round((resultado + Number.EPSILON) * 100) / 100;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style></style>
