<template>
  <v-card elevation="4" class="ma-2 py-2 px-8" width="200" height="480">
    <div
      class="d-flex flex-column justify-space-between pt-3"
      style="height:100%"
    >
      <!-- Aw -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>A<sub>w</sub></i>
              </p>
            </template>
            <span>Área da janela, em m2</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" align="end">
          <v-text-field
            type="number"
            disabled
            reverse
            dense
            v-model="$store.state.Aw"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Af -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>A<sub>f</sub></i>
              </p>
            </template>
            <span>Área do caixilho, em m2</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" align="end">
          <v-text-field
            class="dados"
            type="number"
            disabled
            reverse
            dense
            v-model="$store.state.Af"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Uf -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>U<sub>f</sub></i>
              </p>
            </template>
            <span
              >Coeficiente de transmissão térmica do caixilho, em
              W/(m2.ºC)</span
            >
          </v-tooltip>
        </v-col>
        <v-col cols="6" align="end">
          <v-text-field
            class="dados"
            type="number"
            reverse
            dense
            v-model="$store.state.Uf"
            tabindex="1"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Ag -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>A<sub>g</sub></i>
              </p>
            </template>
            <span>Área do vidro, em m2</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" align="end">
          <v-text-field
            class="dados"
            type="number"
            disabled
            reverse
            dense
            v-model="$store.state.Ag"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Ug -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>U<sub>g</sub></i>
              </p>
            </template>
            <span
              >Coeficiente de transmissão térmica do vidro, em W/(m2.ºC)</span
            >
          </v-tooltip>
        </v-col>
        <v-col cols="6" align="end">
          <v-text-field
            class="dados"
            type="number"
            reverse
            dense
            v-model="$store.state.Ug"
            tabindex="2"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- lg -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>l<sub>g</sub></i>
              </p>
            </template>
            <span>Perímetro de ligação entre o caixilho e o vidro, em m</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" align="end">
          <v-text-field
            class="dados"
            type="number"
            disabled
            reverse
            dense
            v-model="$store.state.lg"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Pgi_g -->
      <v-row align="baseline" no-gutters>
        <v-col cols="6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p class="display-1 text--primary roman" v-bind="attrs" v-on="on">
                <i>&psi;<sub>g</sub></i>
              </p>
            </template>
            <span
              >Coeficiente de transmissão térmica linear relativo à ligação
              entre o caixilho e o vidro, em W/(m.ºC)</span
            >
          </v-tooltip>
        </v-col>
        <v-col cols="6">
          <v-text-field
            class="dados"
            type="number"
            reverse
            dense
            v-model="$store.state.psi"
            tabindex="3"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Tabela -->
      <v-row justify="center">
        <v-btn color="indigo" dark class="mt-1" @click="overlay = !overlay">
          Tabela <span class="roman">&psi;</span>
        </v-btn>

        <v-overlay :value="overlay">
          <v-img src="@/assets/tabela.png"></v-img>
          <v-btn
            class="mt-3"
            color="indigo"
            dark
            absolute
            right
            @click="overlay = false"
          >
            Fechar
          </v-btn>
        </v-overlay>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    overlay: false,
  }),
};
</script>

<style scoped>
.roman {
  font-family: 'Times New Roman', Times, serif !important;
}
.dados >>> input::-webkit-outer-spin-button,
.dados >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dados >>> input[type='number'] {
  -moz-appearance: textfield;
}
</style>
