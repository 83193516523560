<template>
  <v-card elevation="4" class="ma-2" width="990">
    <v-card-text>
      <h4 class="my-3 font-weight-regular">Série:</h4>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
};
</script>

<style></style>
