var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-2 py-2 px-8",attrs:{"elevation":"4","width":"200","height":"480"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between pt-3",staticStyle:{"height":"100%"}},[_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("A"),_c('sub',[_vm._v("w")])])])]}}])},[_c('span',[_vm._v("Área da janela, em m2")])])],1),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-text-field',{attrs:{"type":"number","disabled":"","reverse":"","dense":""},model:{value:(_vm.$store.state.Aw),callback:function ($$v) {_vm.$set(_vm.$store.state, "Aw", $$v)},expression:"$store.state.Aw"}})],1)],1),_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("A"),_c('sub',[_vm._v("f")])])])]}}])},[_c('span',[_vm._v("Área do caixilho, em m2")])])],1),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-text-field',{staticClass:"dados",attrs:{"type":"number","disabled":"","reverse":"","dense":""},model:{value:(_vm.$store.state.Af),callback:function ($$v) {_vm.$set(_vm.$store.state, "Af", $$v)},expression:"$store.state.Af"}})],1)],1),_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("U"),_c('sub',[_vm._v("f")])])])]}}])},[_c('span',[_vm._v("Coeficiente de transmissão térmica do caixilho, em W/(m2.ºC)")])])],1),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-text-field',{staticClass:"dados",attrs:{"type":"number","reverse":"","dense":"","tabindex":"1"},model:{value:(_vm.$store.state.Uf),callback:function ($$v) {_vm.$set(_vm.$store.state, "Uf", $$v)},expression:"$store.state.Uf"}})],1)],1),_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("A"),_c('sub',[_vm._v("g")])])])]}}])},[_c('span',[_vm._v("Área do vidro, em m2")])])],1),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-text-field',{staticClass:"dados",attrs:{"type":"number","disabled":"","reverse":"","dense":""},model:{value:(_vm.$store.state.Ag),callback:function ($$v) {_vm.$set(_vm.$store.state, "Ag", $$v)},expression:"$store.state.Ag"}})],1)],1),_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("U"),_c('sub',[_vm._v("g")])])])]}}])},[_c('span',[_vm._v("Coeficiente de transmissão térmica do vidro, em W/(m2.ºC)")])])],1),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-text-field',{staticClass:"dados",attrs:{"type":"number","reverse":"","dense":"","tabindex":"2"},model:{value:(_vm.$store.state.Ug),callback:function ($$v) {_vm.$set(_vm.$store.state, "Ug", $$v)},expression:"$store.state.Ug"}})],1)],1),_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("l"),_c('sub',[_vm._v("g")])])])]}}])},[_c('span',[_vm._v("Perímetro de ligação entre o caixilho e o vidro, em m")])])],1),_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-text-field',{staticClass:"dados",attrs:{"type":"number","disabled":"","reverse":"","dense":""},model:{value:(_vm.$store.state.lg),callback:function ($$v) {_vm.$set(_vm.$store.state, "lg", $$v)},expression:"$store.state.lg"}})],1)],1),_c('v-row',{attrs:{"align":"baseline","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"display-1 text--primary roman"},'p',attrs,false),on),[_c('i',[_vm._v("ψ"),_c('sub',[_vm._v("g")])])])]}}])},[_c('span',[_vm._v("Coeficiente de transmissão térmica linear relativo à ligação entre o caixilho e o vidro, em W/(m.ºC)")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"dados",attrs:{"type":"number","reverse":"","dense":"","tabindex":"3"},model:{value:(_vm.$store.state.psi),callback:function ($$v) {_vm.$set(_vm.$store.state, "psi", $$v)},expression:"$store.state.psi"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mt-1",attrs:{"color":"indigo","dark":""},on:{"click":function($event){_vm.overlay = !_vm.overlay}}},[_vm._v(" Tabela "),_c('span',{staticClass:"roman"},[_vm._v("ψ")])]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-img',{attrs:{"src":require("@/assets/tabela.png")}}),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"indigo","dark":"","absolute":"","right":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" Fechar ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }