<template>
  <div class="my-2">
    <v-row justify="center" align="center">
      <Serie />
    </v-row>
    <v-row justify="center" align="center">
      <Dados />
      <v-card
        elevation="4"
        class="ma-2 py-2 px-8 card"
        width="774"
        height="480"
      >
        <v-img src="@/assets/1F.png" class="janela"></v-img>
        <div class="largura">
          <v-text-field
            type="number"
            solo
            flat
            dense
            v-model="largura"
            class="text-align-center"
            tabindex="4"
          ></v-text-field>
        </div>
        <div class="altura">
          <v-text-field
            type="number"
            solo
            flat
            dense
            v-model="altura"
            class="text-align-center"
            tabindex="5"
          ></v-text-field>
        </div>
        <div class="v1">
          <v-text-field
            type="number"
            solo
            flat
            dense
            v-model="v1"
            class="text-align-center"
            tabindex="6"
          ></v-text-field>
        </div>
        <div class="v2">
          <v-text-field
            type="number"
            solo
            flat
            dense
            v-model="v2"
            class="text-align-center"
            tabindex="7"
          ></v-text-field>
        </div>
        <div class="h1">
          <v-text-field
            type="number"
            solo
            flat
            dense
            v-model="h1"
            class="text-align-center"
            tabindex="8"
          ></v-text-field>
        </div>
        <div class="h2">
          <v-text-field
            type="number"
            solo
            flat
            dense
            v-model="h2"
            class="text-align-center"
            tabindex="9"
          ></v-text-field>
        </div>
      </v-card>
    </v-row>
    <v-row justify="center" align="center">
      <Resultado />
    </v-row>
  </div>
</template>

<script>
import Serie from '../components/Serie.vue'
import Dados from '../components/Dados';
import Resultado from '../components/Resultado';
export default {
  components: { Serie, Dados, Resultado },
  data() {
    return {
      largura: 1000,
      altura: 1000,
      v1: 120,
      v2: 120,
      h1: 120,
      h2: 120,
    };
  },
  updated() {
    this.updateU();
  },
  created() {
    this.updateU();
  },
  methods: {
    updateU() {
      let area = (this.largura / 1000) * (this.altura / 1000);
      this.$store.state.Aw = Math.round((area + Number.EPSILON) * 100) / 100;
      let areaVidro =
        ((this.largura - this.v1 - this.v2) / 1000) *
        ((this.altura - this.h1 - this.h2) / 1000);
      this.$store.state.Ag =
        Math.round((areaVidro + Number.EPSILON) * 100) / 100;
      let perimetroVidro =
        ((this.largura - this.v1 - this.v2) / 1000) * 2 +
        ((this.altura - this.h1 - this.h2) / 1000) * 2;
      this.$store.state.lg =
        Math.round((perimetroVidro + Number.EPSILON) * 100) / 100;
      let areaCaixilho =
        (this.largura / 1000) * (this.altura / 1000) - this.$store.state.Ag;
      this.$store.state.Af =
        Math.round((areaCaixilho + Number.EPSILON) * 100) / 100;
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
}
.janela {
  position: absolute !important;
  border-radius: 0 !important;
  width: 300px;
  height: 300px;
  left: 50%;
  top: 50%;
  margin: -150px 0px 0px -150px;
}
.largura {
  position: absolute;
  width: 70px;
  left: 50%;
  top: 50%;
  margin: -189px 0px 0px -35px;
}
.altura {
  position: absolute;
  width: 70px;
  left: 50%;
  top: 50%;
  margin: -19px 0px 0px -221px;
}
.v1 {
  position: absolute;
  width: 70px;
  left: 50%;
  top: 50%;
  margin: 151px 0px 0px -170px;
}
.v2 {
  position: absolute;
  width: 70px;
  left: 50%;
  top: 50%;
  margin: 151px 0px 0px 100px;
}
.h1 {
  position: absolute;
  width: 52px;
  left: 50%;
  top: 50%;
  margin: -156px 0px 0px 151px;
}
.h2 {
  position: absolute;
  width: 52px;
  left: 50%;
  top: 50%;
  margin: 112px 0px 0px 151px;
}
.text-align-center >>> input {
  text-align: center;
}

.text-align-center >>> input::-webkit-outer-spin-button,
.text-align-center >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-align-center >>> input[type='number'] {
  -moz-appearance: textfield;
}
</style>
